import axios from "axios";

axios.defaults.timeout = 15000;
axios.defaults.timeoutErrorMessage = "timeout";

interface Arguments {
  url: string;
  method: string;
  data?: Record<string, unknown>;
}

export async function request({ url, method, data = {} }: Arguments): Promise<any> {
  const fullUrl = `/api/v1/${url}`;

  let response;
  switch (method) {
    case "get":
      response = await axios.get(fullUrl, {});
      break;
    case "post":
      response = await axios.post(fullUrl, data, {});
      break;
    case "put":
      response = await axios.put(fullUrl, data, {});
      break;
    case "delete":
      response = await axios.delete(fullUrl, {});
      break;
    case "patch":
      response = await axios.patch(fullUrl, data, {});
      break;
  }

  return response?.data;
}
