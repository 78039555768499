import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { AxiosError } from "axios";
import { getTour } from "@/services/tour.service";
import { Tour } from "@/types/Tour";

const state = () => ({
  tour: {} as Tour,
  tourDate: null as Date | null,
  tourError: '' as string,
});

const gerErrorMessage = (error: unknown): string => {
  if ((error as AxiosError)?.message) {
    return (error as AxiosError).message;
  } else {
    return 'Error';
  }
};

type RootState = ReturnType<typeof state>;

const mutations: MutationTree<RootState> = {
  SET_TOUR: (state, tour: Tour) => {
    state.tour = tour;
  },
  SET_TOUR_DATE: (state, tourDate: Date) => {
    state.tourDate = tourDate;
  },
  SET_TOUR_ERROR: (state, error: string) => {
    state.tourError = error;
  },
};

const actions: ActionTree<RootState, RootState> = {
  async getTour({ commit }, tourId: string) {
    try {
      const res = await getTour(tourId);
      commit('SET_TOUR', res);
      commit('SET_TOUR_ERROR', '');
    } catch (err) {
      commit('SET_TOUR_ERROR', gerErrorMessage(err));
    }
  },
  setTourDate({ commit }, date: Date) {
    commit('SET_TOUR_DATE', date);
  },
};

const getters: GetterTree<RootState, RootState> = {
  tour: ({ tour }) => tour,
  tourDate: ({ tourDate }) => tourDate,
  tourError: ({ tourError }) => tourError,
};

export default {
  mutations,
  actions,
  getters,
  state
};
