export const en = {
  subscription: 'Подборки лучших экскурсий от редакции Трипстера у вас в почте',
  search_label: 'Куда вы собираетесь?',
  about_link: 'О проекте',
  help_link: 'Помощь',
  login: 'Войти',
  favourite_amount: 'В избранном {amount} предложений',
  day_unavailable: 'День занят',
  day_available: 'День свободен',
  duration: 'Длительность',
  from: 'от',

  button: {
    subscribe: 'Подписаться',
    to_favourite: 'Перейти в избранное',
    order: 'Заказать',
    write_me: 'Напишите мне',
  },

  event_type: {
    group: '**Групповая экскурсия для {from}–{to} человек',
    group_short: '**Групповая экскурсия',
    group_short_to: '**Гид проведет экскурсию для вас и вашей компании до {to} человек',
    individual: 'Индивидуальная экскурсия для {from}–{to} человек',
    individual_short: 'Индивидуальная экскурсия',
    individual_short_to: 'Гид проведет экскурсию для вас и вашей компании до {to} человек',
  },

  event_format: {
    title: 'Как проходит',
    walking: 'Пешком',
    car: 'На машине',
  },

  pay_for: {
    person: '**за человека',
    package: 'за экскурсию',
  },

  details: {
    program: 'Что вас ожидает',
  },

  tabs: {
    order: 'Заказать',
    question: 'Задать вопрос',
  },

  days: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
};
