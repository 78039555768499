import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import TourView from '../views/TourView.vue';
import BookingView from '../views/BookingView.vue';
import CommercialProposal from "@/views/CommercialProposal.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/cp',
    name: 'Commercial Proposal',
    component: CommercialProposal
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/tour/:tourId',
    name: 'tour',
    component: TourView
  },
  {
    path: '/booking/:tourId',
    name: 'booking',
    component: BookingView
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
