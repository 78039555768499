import { request } from "@/services/main.service";

const getTour = (tourId: string) => request({
  url: `/tour/${ tourId }`,
  method: 'get',
});

export {
  getTour
};
