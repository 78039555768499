<template>
  <div style="display: flex; justify-content: center; align-content: center">
    <div class="container">
      <h1>Коммерческое предложение для операторов экскурсионных услуг от команды DiscoverLocus</h1>

      <h2>Введение</h2>
      <p>DiscoverLocus предлагает современную платформу, направленную на максимальное упрощение и эффективность процесса продажи и продвижения экскурсий. Мы поможем вам выйти за рамки стандартных методов продвижения и использовать персонализированные каналы для привлечения идеальной целевой аудитории.</p>

      <h2>Ключевые преимущества</h2>
      <ul>
        <li>Актуальные отзывы: Посетители платформы могут оставлять отзывы о ваших экскурсиях, повышая вашу репутацию и доверие со стороны новых клиентов.</li>
        <li>Интерактивное представление: Ваши экскурсии можно дополнить видеоматериалами и виртуальными турами для более полного и привлекательного представления.</li>
      </ul>

      <h2>Как это работает?</h2>
      <p>Ваш контент интегрируется на нашу платформу и становится доступным для распространения через разнообразные каналы продвижения. Клиенты могут просмотреть ваше предложение, общаться с консультантами через Телеграм-боты и совершать покупки или бронирования.</p>

      <h2>Финансовая модель на примерах</h2>
      <table border="1">
        <tr>
          <th>Ваша доля дохода</th>
          <th>Каналы продвижения</th>
          <th>Комиссия партнера</th>
          <th>Комиссия DiscoverLocus</th>
        </tr>
        <tr>
          <td>850 (85%)</td>
          <td>Такси с QR-кодами</td>
          <td>50 (5%)</td>
          <td>100 (10%)</td>
        </tr>
        <tr>
          <td>860 (86%)</td>
          <td>Отели и кафе</td>
          <td>30 (3%)</td>
          <td>110 (11%)</td>
        </tr>
        <tr>
          <td>860 (86%)</td>
          <td>Торговые центры</td>
          <td>20 (2%)</td>
          <td>120 (12%)</td>
        </tr>
        <tr>
          <td>860 (86%)</td>
          <td>Онлайн-платформы</td>
          <td>10 (1%)</td>
          <td>130 (13%)</td>
        </tr>
      </table>

      <h2>Методы продвижения</h2>
      <ol>
        <li>Такси с QR-кодами: Ваши предложения легко доступны для пассажиров такси.</li>
        <li>Отели и кафе: Эффективное сотрудничество с заведениями для расширения вашей аудитории.</li>
        <li>Торговые центры: Ваши экскурсии рекламируются через интерактивные стенды.</li>
        <li>Онлайн-платформы: Интенсивное продвижение в интернете через рекламные кампании и инфлюенсеров.</li>
      </ol>

      <h2>Дополнительные услуги</h2>
      <ul>
        <li>Аналитика и отчетность: Мониторинг эффективности для оптимизации вашей стратегии.</li>
        <li>Поддержка и обучение: Круглосуточная поддержка и обучение для максимального использования возможностей платформы.</li>
        <li>Кастомизация: Персонализация платформы в стиле вашего бренда для повышения узнаваемости.</li>
      </ul>

      <p>Для детального обсуждения и демонстрации возможностей платформы, пожалуйста, свяжитесь с нами.</p>

      <p>С уважением,<br>
        Команда DiscoverLocus</p>


    </div>
  </div>


</template>

<script lang='ts' setup>

</script>

<style lang='scss' scoped>
.container {
  max-width: 750px;
  margin: 0 auto;
  padding: 20px;
}
@media screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }
}

</style>
